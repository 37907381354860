<template>
    <div class="checkout_wrapper">
        <div class="address">
            <button class="btn_address">
                添加收货地址
            </button>
        </div>

        <div class="prescription">
            <div class="prescription_tip">
                <p class="prescription_tip_write">
                    填写处方信息
                </p>
                <p class="prescription_tip_warn">
                    处方药品，需要凭处方购买
                </p>
            </div>
            <button class="btn_add_prescription">
                去添加
            </button>
        </div>

        <div class="medicine">
            <div class="store" />
            <ul />
            <div class="total" />
        </div>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.checkout_wrapper {
    width: 100%;
    height: 100%;
    background: #f5f7fa;
    .address {
        // margin-top: 8px;
        padding-top: 8px;
        width: 100%;
        height: 56px;
        background: #fff;
        background-clip: content-box;
        box-sizing: content-box;
        border-bottom: 4px solid;
        border-image: url('./line.png') 18 18 18 18;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn_address {
            width: 128px;
            height: 32px;
            outline: none;
            border: none;
            background: linear-gradient(128deg, #ffd169 0%, #fd6500 100%);
            border-radius: 21px;

            // height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            line-height: 32px;
        }
    }
    .prescription {
        box-sizing: border-box;
        width: 100%;
        height: 78px;
        background: #fff;
        box-shadow: 0 0 8px 0 rgba(60, 107, 240, 0.1);
        margin-top: 8px;
        padding: 16px 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .prescription_tip {
            .prescription_tip_write {
                height: 22px;
                font-size: 16px;
                font-weight: 500;
                color: #333;
                line-height: 22px;
                margin-bottom: 4px;
                &::before {
                    content: '';
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    background: url('./prescription.png');
                    background-size: 16px 16px;
                    margin-right: 6px;
                    position: relative;
                    top: 2px;
                }
            }
            .prescription_tip_warn {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #62636c;
                line-height: 20px;
            }
        }
        .btn_add_prescription {
            width: 74px;
            height: 32px;
            background: #fff;
            border-radius: 16px;
            border: 1px solid #f36218;
            color: #f36218;
            font-size: 14px;
            font-weight: 500;
        }
    }
    // .medicine {
    //     .store {

    //     }
    //     .total {

    //     }
    // }
}

</style>
